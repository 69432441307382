/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.

jQuery(".element").typed({
  strings: ["Hulp nodig?", "Wij helpen graag."],
  typeSpeed: 150,
  backDelay: 1500,
  zoomControl: false,
  loop: true
});

jQuery("#slider").owlCarousel({
      nav : false,
      margin:30,
      stagePadding: 30,
      responsive:{
      0:{
          items:1,
          margin:15,
          stagePadding: 15,
      },
      768:{
          items:2,
          margin:15,
          stagePadding: 15,
      },
      991:{
          items:3
      },
      1300:{
          items:4
      },
      1680:{
          items:5
      }
  }
});

jQuery(".reviews-slider").owlCarousel({
      nav : true,
      items: 1,
      autoHeight: true,
      navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
      autoplay:true,
      autoplayTimeout:5000, 
      autoplayHoverPause:true,
});


jQuery("#owl-demo").owlCarousel({
      nav : false,
      margin:30,
      stagePadding: 30,
      loop: true,
      autoplay:true,
      autoplayTimeout:3500,
      autoplayHoverPause:true,
      responsive:{
      0:{
          items:1
      },
      525:{
          items:2
      },
      768:{
          items:2
      },
      991:{
          items:3
      },
      1500:{
          items:4
      }
  }
});


jQuery(document).ready(function() {


  if (jQuery(window).width() > 768) {
    jQuery('ul.nav li.menu-item-has-children').hover(function() {
      jQuery(this).find('.sub-menu').stop(true, true).delay(200).fadeIn(0);
    }, function() {
      jQuery(this).find('.sub-menu').stop(true, true).delay(200).fadeOut(0);
    });
  }

  var masonryInit = true; // set Masonry init flag
  jQuery.fn.almComplete = function(alm){ // Ajax Load More callback function
    if(jQuery('#masonry-grid').length){
      var $container = jQuery('#masonry-grid ul'); // our container
      if(masonryInit){
        // initialize Masonry only once
        masonryInit = false;
        $container.masonry({
          itemSelector: '.blogpost'
        });
      }else{
          $container.masonry('reloadItems'); // Reload masonry items after callback
      }
    }
  };

});




jQuery(document).ready(function($) {


      $('#menu-menu-1 .icon').click(function (event) {

          event.stopImmediatePropagation();

          $(this).toggleClass('fa fa-plus fa fa-minus');
          $(this).parent().toggleClass('open');

      });


      jQuery('.scrollup').on('click', scrollToTop);

      function scrollToTop() {
        verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
        element = jQuery('body');
        offset = element.offset();
        offsetTop = offset.top;
        jQuery('html, body').animate({scrollTop: offsetTop}, 500, 'linear');
      }

});





jQuery(document).ready(function($) {

  jQuery('.paneel .collapse').on('shown.bs.collapse', function(){
    jQuery(this).parent().find(".fa-plus").removeClass("fa-plus").addClass("fa-minus");
  }).on('hidden.bs.collapse', function(){
    jQuery(this).parent().find(".fa-minus").removeClass("fa-minus").addClass("fa-plus");
  });

  $('#bs-example-navbar-collapse-1').on('show.bs.collapse', function () {
    $('html').addClass('noscroll');
    $('.header-main').removeClass('affix-top');
    $('.header-main').addClass('affix');
  });
  $('#bs-example-navbar-collapse-1').on('hide.bs.collapse', function () {
    $('html').removeClass('noscroll');
    $('.header-main').removeClass('affix');
    $('.header-main').addClass('affix-top');
  });

});
